import axios from '@axios'
import ApiService from "@/services/pamisDashboardApi.service"

// const baseURL = process.env.VUE_APP_API_URL + '/pamis/admin/'
var baseURL = "https://limaapitest.eldor.com.tr/pamis/admin/"
// const baseURL = 'https://apitest.niso.dev/pamis/admin/'
//const baseURL = 'https://apitest.niso.dev/pamis/admin/'

export default {
  namespaced: true,
  state: {
    dateFilters: null,
    dateFilterId: 2,
    jobFields: null,
    jobOperatorFields: null,
  },
  getters: {
    GetDateFilters(state) {
      return state.dateFilters
    },
    GetDateFilterId(state) {
      return state.dateFilterId
    },
    GetJobFields(state) {
      return state.jobFields
    },
    GetOperatorJobFields(state) {
      return state.operatorJobFields
    },
  },
  mutations: {
    SetDateFilters(state, filters) {
      state.dateFilters = filters
    },
    SetDateFilterId(state, newDateFilterId) {
      state.dateFilterId = newDateFilterId
    },
    SetJobFields(state, newJobFields) {
      state.jobFields = newJobFields
    },
    SetOperatorJobFields(state, newOperatorJobFields) {
      state.operatorJobFields = newOperatorJobFields
    },
  },

  actions: {
    fetchJobComments(ctx, jobId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}workorder/Comment/Comments?JobId=${jobId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobs() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}workorder/Job/Jobs`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOperatorJobs() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}workorder/JobEffort/JobEfforts`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobFields(ctx) {

      return new Promise((resolve,) => {
        axios
          .get(`${baseURL}workorder/Job/JobFields`)
          .then((response) => {
            ctx.commit("SetJobFields", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetJobFields", null);
            resolve(error);
          });
      })
    },
    fetchJobEffortFields(_, param) {
      return new Promise((resolve,) => {
        axios
          .get(`${baseURL}workorder/JobEffort/JobEffortFields?deviceId=${param}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            resolve(error)
          })
      })
    },
    createJobEffort(_, jobEffort) {
      return new Promise((resolve,) => {
        axios
          .post(`${baseURL}workorder/JobEffort/Create`, jobEffort)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            resolve(error)
          })
      })
    },
    fetchWorkCenters() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}Organization/GetWorkCentersBySiteId?siteId=100`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}Job/WorkCenter/GetJobData?workcenterId=${data.workCenterID}&dateFilterId=${data.dateFilterId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateJobData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}Job/UpdateJobData`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetDateFilters(ctx) {
      return new Promise((resolve,) => {
        ApiService.get("Utility/Site", "GetDateFilters")
          .then((response) => {
            ctx.commit("SetDateFilters", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetDateFilters", null);
            resolve(error);
          });
      });
    },
    SetDateFilterId(ctx, newId) {
      ctx.commit('SetDateFilterId', newId)
    },
    CreateJob(ctx, job) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}workorder/Job/Create`, { job })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    CreateJobComment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}workorder/Comment/Create`, { comment: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateJobComment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}workorder/Comment/Update?id=${params.id}`, { comment: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteJobComment(ctx, commentId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${baseURL}workorder/Comment/Delete?id=${commentId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    UpdateJob(ctx, job) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}workorder/Job/Update?id=${job.id}`, { job })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    CancelJob(ctx, jobId) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}workorder/Job/Cancel?id=${jobId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
